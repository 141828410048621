

















































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import EventService from '@/services/Event.service'
import OfferService from '@/services/Offer.service'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { Pagination as IPagination } from '@/interfaces/pagination'
import { groupBy } from 'underscore'
import { InvestorEvent, EventToCSV } from '@/interfaces/event'
import { Offer } from '@/interfaces/offer'

interface FilterOptions {
  email: string | null;
  oferta: string | null;
}

@Component({
  components: {
    Pagination,
    JsonExcel
  }
})
export default class Events extends Vue {
  private eventService = new EventService();
  private offerService = new OfferService();

  EventToCSV = EventToCSV
  $moment;

  events: InvestorEvent[] = [];
  userEvents: InvestorEvent[] = [];

  offers: Offer[] = [];

  options = {
    itemsPorPagina: 20,
    pagina: 0
  };

  filter: FilterOptions = {
    email: null,
    oferta: null
  };

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: '',
    viewMode: 'list', // or timeline
    selectedUserEmail: ''
  };

  pagination: IPagination = {} as IPagination;

  get userEventsByDate() {
    if (!this.state.selectedUserEmail) return {}

    const events = this.userEvents.map((e) => {
      const [date, time] = e.data.split(' ')

      return {
        ...e,
        date,
        time
      }
    })

    return groupBy(events, 'date')
  }

  orderByDate(dates) {
    return dates.sort((a, b) => {
      return this.$moment(b, 'DD/MM/YYYY').diff(this.$moment(a, 'DD/MM/YYYY'))
    })
  }

  orderByTime(events: any[]) {
    return events.sort((a, b) => {
      return this.$moment(b.time, 'HH:mm:ss').diff(this.$moment(a.time, 'HH:mm:ss'))
    })
  }

  mounted() {
    this.loadOffers()
    this.loadEvents()
  }

  loadOffers() {
    this.offerService.all().then((offers) => {
      this.offers = offers
    })
  }

  loadEvents(opts = this.options) {
    this.state.loading = true

    this.eventService
      .all(opts)
      .then((data) => {
        this.events = data.items
        this.pagination = data.pagination
      })
      .then(() => {
        this.state.loading = false
      })
  }

  onToPage(n) {
    this.$set(this.options, 'pagina', n)

    const opts = {
      ...this.options,
      ...this.filter
    }

    this.loadEvents(opts)
  }

  startDownload() {
    this.state.downloadLoading = true
  }

  finishDownload() {
    this.state.downloadLoading = false
  }

  onFilterReset() {
    this.filter = { email: null, oferta: null }
    this.onFilterSubmit()
  }

  onFilterSubmit() {
    const opts = {
      ...this.options,
      ...this.filter,
      pagina: 0
    }

    this.$emit('label-change', 'Todos')
    this.state.viewMode = 'list'
    this.state.selectedUserEmail = ''

    this.loadEvents(opts)
  }

  async showTimeline(email) {
    this.$emit('label-change', email)
    this.state.viewMode = 'timeline'
    this.state.selectedUserEmail = email
    this.state.isFilterOpen = false

    const opts = {
      ...this.options,
      ...this.filter,
      pagina: 0,
      itemsPorPagina: 100,
      email: email
    }

    const response = await this.eventService.all(opts)

    this.userEvents = response.items
  }

  async fetchData() {
    const response = await this.eventService.all({ ...this.filter, paginacao: false })
    return response.items
  }
}
