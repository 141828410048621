


















































































import Vue from 'vue'
import Component from 'vue-class-component'
import UserService from '@/services/User.service'
import EventService from '@/services/Event.service'
import InvestmentService from '@/services/Investment.service'
import Pagination from '@/components/Pagination.vue'
import JsonExcel from 'vue-json-excel'
import { Pagination as IPagination } from '@/interfaces/pagination'
import { InvestorEvent } from '@/interfaces/event'
import Events from '@/components/Events.vue'

interface FilterOptions {
  nome: string | null;
  email: string | null;
  cpf: string | null;
  telefone: string | null;
}

interface Tab {
  id: number;
  label: string;
  active: boolean;
}

@Component({
  components: {
    Pagination,
    JsonExcel,
    Events
  }
})
export default class Users extends Vue {
  private userService = new UserService();
  private eventService = new EventService();
  private investmentService = new InvestmentService();

  events: InvestorEvent[] = []

  tabs: Tab[] = [
    { id: Date.now(), label: 'Todos', active: true }
  ]

  options = {
    itemsPorPagina: 20,
    pagina: 0
  }

  filter: FilterOptions = {
    cpf: null,
    email: null,
    nome: null,
    telefone: null
  }

  state = {
    downloadLoading: false,
    isFilterOpen: false,
    loading: false,
    error: ''
  }

  pagination: IPagination = {} as IPagination

  async mounted() {
    this.loadEvents()
  }

  loadEvents(opts = this.options) {
    this.state.loading = true

    this.eventService.all(opts)
      .then(data => {
        this.events = data.items
        this.pagination = data.pagination
      })
      .then(() => {
        this.state.loading = false
      })
  }

  onToPage(n) {
    this.$set(this.options, 'pagina', n)

    const opts = {
      ...this.options,
      ...this.filter
    }

    this.loadEvents(opts)
  }

  newTab() {
    this.tabs = this.tabs.map(t => t.active ? { ...t, active: false } : t)

    this.tabs.push({
      id: Date.now(),
      label: 'Todos',
      active: true
    } as Tab)
  }

  onLabelChange(label) {
    this.tabs = this.tabs.map(t => t.active ? { ...t, label: label } : t)
  }

  toggleActive(newActiveTab: Tab) {
    const currentActive: Tab = this.tabs.find(tab => tab.active) as Tab
    currentActive.active = false

    newActiveTab.active = true
  }

  closeTab(tab) {
    const index = this.tabs.indexOf(tab)

    if (!tab.active) {
      this.tabs = this.tabs.filter(t => t.id !== tab.id)
      return
    }

    if (index === 0) {
      if (this.tabs.length === 1) return

      this.toggleActive(this.tabs[1])
      this.tabs = this.tabs.filter(t => t.id !== tab.id)
      return
    }

    this.toggleActive(this.tabs[index - 1])
    this.tabs = this.tabs.filter(t => t.id !== tab.id)
  }
}

