export interface InvestorEvent {
  id: string;
  email_client: string;
  url_oferta: string;
  descricao: string;
  data: string;
}

export const EventToCSV = {
  Email: 'email_client',
  Oferta: 'url_oferta',
  Tipo: 'descricao',
  Data: 'data'
}
