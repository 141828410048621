import axios, { AxiosInstance, AxiosResponse } from 'axios'
import { Pagination } from '@/interfaces/pagination'
import { InvestorEvent } from '@/interfaces/event'

export default class EventService {
  public ENDPOINT = 'https://feed-dot-startmeup-218419.rj.r.appspot.com'

  client: AxiosInstance;

  constructor(client: AxiosInstance = axios) {
    this.client = client
  }

  all(options: any): Promise<{ items: InvestorEvent[]; pagination: Pagination }> {
    return this.client
      .get(`${this.ENDPOINT}/feed/lista`, { params: options })
      .then((response: AxiosResponse) => response.data)
      .then((data) => {
        return {
          items: data.items,
          pagination: {
            itemsPorPagina: data.itemsPorPagina,
            numeroDePaginas: data.numeroDePaginas,
            pagina: data.pagina,
            proximaPagina: data.proximaPagina,
            temPaginaAnterior: data.temPaginaAnterior,
            temProximaPagina: data.temProximaPagina
          }
        }
      })
  }
}
